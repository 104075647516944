// import CSS
import styles from '../css/app.scss';

// import fonts
// import fontname  from '../fonts/fontname.woff';
// import fontname2 from '../fonts/fontname.woff2';

// app main
const main = async () => {

    // Async load the vue module
    const { default: Vue } = await import(/* webpackChunkName: "vue" */ 'vue');

    // load the vue waypoint
    const { default: VueWaypoint } = await import(/* webpackChunkName: "vuewaypoint" */ 'vue-waypoint');
    Vue.use(VueWaypoint)

    // Create our vue instance
    const vm = new Vue({
        el: "#page-container",

        components: {
            'emergencymessage': () => import(/* webpackChunkName: "emergencymessage" */ '../vue/EmergencyMessage.vue'),
            'mobilemenu': () => import(/* webpackChunkName: "mobilemenu" */ '../vue/MobileMenu.vue'),
            'shopifyproduct': () => import(/* webpackChunkName: "shopifyproduct" */ '../vue/ShopifyProduct.vue'),
            'shopifycart': () => import(/* webpackChunkName: "shopifycart" */ '../vue/ShopifyCart.vue')
        },

        data: {
            galleryLeft: 0,
            galleryRight: 0,
            intersectionOptions: {
                threshold: [0.5]
            }
        },

        methods: {
            handleScroll (event) {
                if( window.pageYOffset >= 10 ) {
                    document.body.classList.add("scrolled")
                }

                if( window.pageYOffset == 0 ) {
                    document.body.classList.remove("scrolled")
                }

                if( this.$refs["gallery"] ) {
                    let rect = this.$refs["gallery"].getBoundingClientRect()
                    let inView = (
                        rect.width > 0 &&
                        rect.height > 0 &&
                        rect.top <= window.innerHeight 
                    )
    
                    if( inView ) {
                        let left = ( ( window.scrollY + window.innerHeight ) - this.$refs["gallery"].offsetTop ) / 10
                        this.galleryLeft = -1 * left
                        this.galleryRight = left
                    }
                }

            },

            onWaypoint({ el, going, direction }) {
                if( this.$waypointMap.GOING_IN === going ) {
                    el.classList.add('active')
                }
            }
        },

        mounted() {


        },

        computed: {
            inView() {
                let rect = this.$refs["gallery"].getBoundingClientRect()
                let inView = (
                  rect.width > 0 &&
                  rect.height > 0 &&
                  rect.top >= 0 &&
                  rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
                )
                
                return inView
            }
        },

        created () {
            document.body.classList.add("js-loaded")
            window.addEventListener('scroll', this.handleScroll)
        },
       
        destroyed () {
            window.removeEventListener('scroll', this.handleScroll)
        },
    });

    return vm;
};

// Execute async function
main().then( (vm) => {

});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
    module.hot.accept();
}
